<template>
  <div>

    <!-- <CWidgetAnnouncementBar class="mb-6">
      Book & Sail %:&nbsp;<NuxtLink href="/kreuzfahrten" class="text-mongoose-300">Kreuzfahrt-Deals</NuxtLink>
    </CWidgetAnnouncementBar> -->

    <CPageWrapper override-styles-header="pt-5 sm:pt-8 mb-6">
      <slot />
    </CPageWrapper>

  </div>
</template>
